import {focusElement} from '@wix/wix-events-commons-statics'
import React from 'react'
import {hookToAttributeSelector, DH} from '../../../constants/data-hooks'
import {AddToCalendar} from '../common/add-to-calendar'
import {Container} from '../common/container'
import {Subtitle} from '../common/subtitle'
import {Title} from '../common/title'
import {Desktop} from './desktop'
import {Mobile} from './mobile'
import s from './order-success.scss'
import {OrderSuccessProps} from './index'

export const OrderSuccess: React.FC<OrderSuccessProps> = (props) => {
  const {
    dateTbd,
    eventTitle,
    formButtonStyle,
    calendarLinks,
    isAssignedTickets,
    t,
    addToCalendarClicked,
    order: {firstName, email},
    template,
  } = props
  const registrationCompleteMsg = isAssignedTickets
    ? 'ticketsThankYou.regCompleteWithAssignedTickets'
    : 'ticketsThankYou.regComplete'
  const ticketsSentMsg = isAssignedTickets
    ? 'ticketsThankYou.ticketSentWithAssignedTickets'
    : 'ticketsThankYou.ticketSent'
  const isAddToCalendarVisible = !dateTbd

  React.useEffect(() => {
    if (!template) {
      props.ticketedThankYouPageLoaded()
    }

    focusElement(hookToAttributeSelector(DH.ORDER_SUCCESS_TITLE))
  }, [])

  return (
    <Container dataHook={DH.ORDER_SUCCESS}>
      <div className={s.header}>
        <Title className={s.title} text={t('ticketsThankYou.thanks', {firstName})} dataHook={DH.ORDER_SUCCESS_TITLE} />
        <Subtitle
          lines={[t(registrationCompleteMsg, {eventTitle}), t(ticketsSentMsg, {email})]}
          dataHook={DH.ORDER_SUCCESS_SUBTITLE}
          isAddToCalendarHidden={!isAddToCalendarVisible}
        />
        <AddToCalendar
          className={s.addToCalendar}
          visible={isAddToCalendarVisible}
          buttonText={t('ticketsThankYou.addToCalendar')}
          formButtonStyle={formButtonStyle}
          calendarLinks={calendarLinks}
          onClick={addToCalendarClicked}
          t={t}
        />
      </div>
      <div className={s.desktop} data-hook={DH.DESKTOP}>
        <Desktop {...props} />
      </div>
      <div className={s.mobile} data-hook={DH.MOBILE}>
        <Mobile {...props} />
      </div>
    </Container>
  )
}
